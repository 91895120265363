import type { NavigationMenuItem } from '@revolutionprep/types'

export const useGlobalStore = defineStore('global', () => {
  // state
  const pageTitle = ref('')
  const pageTitleIcon = ref('')
  const spoofBar = ref(false)
  const searchBar = ref(false)
  const isLoading = ref(true)
  const isProcessing = ref(false)
  const menuItems = ref<NavigationMenuItem[]>([])
  const showPasswordSetDialog = ref(false)
  const showStudentReviewDialog = ref(false)
  const showStudyAreasDialog = ref(false)
  const showSelfMatchHelpDialog = ref(false)

  return {
    pageTitle,
    pageTitleIcon,
    spoofBar,
    searchBar,
    isLoading,
    isProcessing,
    menuItems,
    showSelfMatchHelpDialog,
    showStudentReviewDialog,
    showStudyAreasDialog,
    showPasswordSetDialog
  }
})
